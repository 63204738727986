export interface AffiliateNetwork {
  name: string;
  urlParameters: string[];
}

export interface AffiliateLeadParams extends Record<string, string | undefined> {
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
}

export interface AffiliateLead {
  utm_campaign?: string;
  utm_content?: string;
  tracking_data: Record<string, string> | null;
}

export interface AffiliateLeadAdapter {
  save: (lead: AffiliateLead) => void;
  get: () => AffiliateLead | null;
}

type NetworkIdentifier = (utm_campaign: string) => AffiliateNetwork | null;
type TrackingDataBuilder = (
  network: AffiliateNetwork,
  params: AffiliateLeadParams
) => Record<string, string> | null;
type ParamsValidation = (params: AffiliateLeadParams) => boolean;
type LeadMapper = (params: AffiliateLeadParams) => AffiliateLead;

interface AffiliateLeadService {
  areValidParams: ParamsValidation;
  mapParamsToLead: LeadMapper;
}

const createNetworkIdentifier =
  (networks: AffiliateNetwork[]): NetworkIdentifier =>
  (utmCampaign: string): AffiliateNetwork | null =>
    networks.find((network) => utmCampaign.toLowerCase().includes(network.name)) ?? null;

const createTrackingDataBuilder =
  (): TrackingDataBuilder =>
  (network: AffiliateNetwork, params: AffiliateLeadParams): Record<string, string> | null => {
    const hasAllParameters = network.urlParameters.every((param) => {
      const value = params[param];

      return typeof value === "string" && value.trim().length > 0;
    });

    if (!hasAllParameters) {
      return null;
    }

    const trackingData = network.urlParameters.reduce<Record<string, string>>((acc, param) => {
      const value = params[param];
      if (typeof value === "string" && value.trim().length > 0) {
        acc[param] = value.trim();
      }

      return acc;
    }, {});

    return Object.keys(trackingData).length > 0 ? trackingData : null;
  };

const createParamsValidator =
  (
    networkIdentifier: NetworkIdentifier,
    trackingDataBuilder: TrackingDataBuilder
  ): ParamsValidation =>
  (params: AffiliateLeadParams): boolean => {
    if (params.utm_medium !== "affiliate" || !params.utm_campaign?.trim()) {
      return false;
    }

    const network = networkIdentifier(params.utm_campaign);
    if (!network) {
      return false;
    }

    return trackingDataBuilder(network, params) !== null;
  };

const createLeadMapper =
  (networkIdentifier: NetworkIdentifier, trackingDataBuilder: TrackingDataBuilder): LeadMapper =>
  (params: AffiliateLeadParams): AffiliateLead => {
    const { utm_campaign, utm_content } = params;

    if (!utm_campaign?.trim()) {
      return { utm_campaign, utm_content, tracking_data: null };
    }

    const network = networkIdentifier(utm_campaign);
    const tracking_data = network ? trackingDataBuilder(network, params) : null;

    return { utm_campaign, utm_content, tracking_data };
  };

export const affiliateLeadService = (networks: AffiliateNetwork[]): AffiliateLeadService => {
  const networkIdentifier = createNetworkIdentifier(networks);
  const trackingDataBuilder = createTrackingDataBuilder();

  return {
    areValidParams: createParamsValidator(networkIdentifier, trackingDataBuilder),
    mapParamsToLead: createLeadMapper(networkIdentifier, trackingDataBuilder),
  };
};
