import { affiliateLeadAdapter } from "../data/affiliate-lead/affiliate-lead-adapter";
import {
  AffiliateLead,
  AffiliateLeadAdapter,
  affiliateLeadService,
} from "../domain/affiliate-lead/affiliate-lead";
import { networkConfigs } from "../settings/affiliate-networks";

export const setupAffiliateLead = (affiliateLeadAdapter: AffiliateLeadAdapter): void => {
  const { areValidParams, mapParamsToLead } = affiliateLeadService(networkConfigs);

  const extractUrlParams = (): Record<string, string | undefined> => {
    const searchParams = new URLSearchParams(window.location.search);
    const params: Record<string, string | undefined> = {
      utm_medium: searchParams.get("utm_medium") ?? undefined,
      utm_campaign: searchParams.get("utm_campaign") ?? undefined,
      utm_content: searchParams.get("utm_content") ?? undefined,
    };

    const utmCampaign = params.utm_campaign?.toLowerCase();
    if (!utmCampaign) {
      return params;
    }

    const network = networkConfigs.find((config) => utmCampaign.includes(config.name));

    if (network) {
      network.urlParameters.forEach((param) => {
        params[param] = searchParams.get(param) ?? undefined;
      });
    }

    return params;
  };

  const params = extractUrlParams();

  if (!areValidParams(params)) {
    return;
  }

  const affiliateLead = mapParamsToLead(params);
  affiliateLeadAdapter.save(affiliateLead);
};

export const useAffiliateLead = (): AffiliateLead | null => {
  const adapter = affiliateLeadAdapter();

  return adapter.get();
};
